import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {  unstable_batchedUpdates } from 'react-dom';
//import HeaderComponent from '../../helper/Navhelp';
import classNames from 'classnames';
//import navigationmenu from '../../data/navigation.json';
//import Canvas from './Canvas';
import useMediaQuery from "../../helper/hookMedia"
import LanguageSelect from '../../languageSelect';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

function Header (props) {
    const [navigationmenu, setNavigationmenu]=useState([]);
    const [isTop, setIsTop]=useState(false);
    const [navmethod,setNavmethod]=useState(false);
    const { t, i18n } = useTranslation();
    const location = useLocation();

    if (navigationmenu.length==0){
        Promise.all([fetch(`data/navigation.json`,{
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            }).then(function(response){
            console.log(response)
            return response.json();
            }).then(function(myJson) {
            console.log(myJson);
            return myJson;
        })]).then((r)=>{
            unstable_batchedUpdates(()=>{
                setNavigationmenu(r[0])
            })
        }).catch((error) => {
            console.log(error);
        })    
    }

    const { pathname } = location;
    const splitLocation = pathname.split("/"); 
    const isMobile = useMediaQuery('(max-width:1024px)');
 
    const breakpoint = isMobile ? 'breakpoint-on' : '';
    const hideClass = isMobile ? 'd-none' : '';
    const stickyHeader = isTop ? 'sticky-active' : '';
    
    const toggleNav=()=>{
        setNavmethod(!navmethod)
    };

    window.addEventListener('scroll', () => {
        if (window.scrollY > 150){
            setIsTop(true);    
        }
        else
        {
            setIsTop(false); 
        }
    }, false);

    const getNextSibling = function (elem, selector) {
        // Get the next sibling element
        var sibling = elem.nextElementSibling;
        // If there's no selector, return the first sibling
        if (!selector) return sibling;
        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling
        }
    }
    const triggerChild = (e) => {
        let subMenu = '';
        subMenu = (getNextSibling(e.target, '.submenu') !== undefined) ? getNextSibling(e.target, '.submenu') : null;
        if (subMenu !== null && subMenu !== undefined && subMenu !== '') {
            subMenu.classList = subMenu.classList.contains('d-block') ? 'submenu' : 'submenu d-block';
        }
    }

    return (
        <Fragment>
            <header className={`header-absolute${isTop?"-sticky":""} header-two${stickyHeader || props.headercolor=="black"?"black":""} sticky-header ${stickyHeader}`}>
            {/* <header className={`header-absolute${isTop?"-sticky":""} header-two sticky-header ${stickyHeader}`}> */}
                <div className="container container-custom-one">
                    <div className={`nav-container ${isMobile?"position-relative":"row"} ${breakpoint}`}>
                        {/* Navbar Toggler */}
                        <div className={classNames("navbar-toggler", { "active": navmethod })} onClick={toggleNav}>
                            <span /><span /><span />
                        </div>
                        {/* Site Logo */}
                        <div className={`site-logo d-flex align-items-center ${isMobile?"pt-0 position-absolute top-50 start-50 translate-middle": "col-md-2"}`}>
                            <Link to="/" className={`main-logo w-100 d-flex align-items-center justify-content-center ${isTop || props.headercolor=="black"?"d-none":""}`}>
                                <img src={process.env.PUBLIC_URL + "/assets/img/logo-white.png"} alt="logo" />
                            </Link>
                            <Link to="/" className={`sticky-logo w-100 d-flex align-items-center justify-content-center ${isTop || props.headercolor=="black"?"":"d-none"}`}>
                                <img src={process.env.PUBLIC_URL + "/assets/img/logo.png"} alt="logo" />
                            </Link>
                        </div>
                        {/* Main Menu */}
                        <div className={classNames("nav-menu col-md-10 d-lg-flex justify-content-end align-items-center", { "menu-on": navmethod })}>
                            {/* Navbar Close Icon */}
                            <div className="navbar-close" onClick={toggleNav}>
                                <div className="cross-wrap"><span className="top" /><span className="bottom" /></div>
                            </div>
                            {/* Mneu Items */}
                            <div className={`menu-items text-${props.headercolor}`}>
                                <ul>
                                    {navigationmenu.length > 0 ? navigationmenu.filter(i=>i.activ==true).map((item, i) => (
                                        <li key={i} className={`menu-item ${item.child ? 'menu-item-has-children' : ''} `} onClick={triggerChild}>
                                            {item.child ? <Link onClick={e => e.preventDefault()} to="/"> {i18n.t(item.linkText)} </Link> 
                                                        : <Link to={item.link} className={`${splitLocation[1] ===item.link || splitLocation[1] === '/' && item.link==='acasa' ? "active":""}`}> {i18n.t(item.linkText)} </Link>}
                                            {item.child ?
                                                <ul className="submenu" role="menu">
                                                    {item.submenu.map((sub_item, i) => (
                                                        <li key={i} className={`menu-item ${sub_item.child ? 'menu-item-has-children' : ''} `}>
                                                            {sub_item.child ? <Link onClick={e => e.preventDefault()} to="/"> {i18n.t(sub_item.linkText)} </Link> : <Link to={sub_item.link}> {i18n.t(sub_item.linkText)} </Link>}
                                                            {sub_item.submenu ?
                                                                <ul className="submenu">
                                                                    {sub_item.submenu.map((third_item, i) => (
                                                                        <li className="menu-item" key={i}><Link
                                                                            to={third_item.link}>{i18n.t(third_item.linkText)}</Link>
                                                                        </li>
                                                                    ))}
                                                                </ul> : null}
                                                        </li>
                                                    ))}
                                                </ul>
                                                : null
                                            }
                                        </li>
                                    )) : null}
                                </ul>
                            </div>
                            {/* from pushed-item */}
                            <div className="nav-pushed-item" />
                        </div>
                        {/* Header Info Pussed To Menu Wrap */}
                        <div className={`nav-push-item d-none col-md-5 d-flex justify-content-end ${hideClass}`}>
                            {/* Header Info */}
                            <div className="header-info d-lg-flex align-items-center">
                                <div className="item">
                                    {/* <i className="fal fa-phone"/> */}
                                    <span >{i18n.t("contact.contact_telefon")}</span>
                                    <h5>
                                        <a href="tel:+40256201023" > +40 256 201 023</a>
                                    </h5>
                                </div>
                                <div className="item">
                                    {/* <i className="fal fa-envelope"/> */}
                                    <span>{i18n.t("contact.contact_email")}</span>
                                    <h5>
                                        <a href="mailto:office@superimposer.com" >office@superimposer.com</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className={`language-select 
                                        ${isMobile?"position-absolute top-0 end-0":"col-md-1"} 
                                        language-select-dropdown${stickyHeader?"-sticky":""} 
                                        d-flex justify-content-end`}>
                            <LanguageSelect />
                        </div> */}
                    </div>
                </div>
            </header>
        </Fragment>
    );
}

export default Header;