import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Backtotop from './Backtotop';
//import { useTranslation } from 'react-i18next';

function Footer(props) {
    //const { t, i18n } = useTranslation();
    return (
        <Fragment>
            {/* <Backtotop /> */}
            <footer className="footer-two">
                <div className={"footer-widget-area pt-100 pb-50 " + props.footer.style}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 order-1">
                                {/* Site Info Widget */}
                                <div className="widget site-info-widget mb-50">
                                    <div className="footer-logo mb-50">
                                        <img src={process.env.PUBLIC_URL + "/" + props.footer.logo} alt="logo" />
                                    </div>
                                    <h5 className="widget-title mb-10">Super Imposer<span></span></h5>
                                    <p>Transport persoane intern și internațional<br/>Curse regulate, speciale<br/>Închirieri autocare, autobuze și microbuze</p>
                                    <div className="social-links mt-40">
                                        <a target="_blank" href="https://www.facebook.com/pages/Super%20Imposer/234836899971475/">
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                        <a target="_blank" href="https://ro.linkedin.com/in/superimposer-superimposer-87350895">
                                            <i className="fab fa-linkedin" />
                                        </a>
                                        <Link to="#"><i className="fab fa-twitter" /></Link>
                                        <Link to="#"><i className="fab fa-youtube" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-3 order-lg-2">
                                {/* Nav Widget */}
                                <div className="widget nav-widget mb-50">
                                    <div>
                                        <h4 className="widget-title">Link-uri rapide</h4>
                                        <ul>
                                            <li><Link to="trasee">Trasee și orare</Link></li>
                                            <li><Link to="parteneri">Parteneri</Link></li>
                                            <li><Link to="anunturi">Anunțuri</Link></li>
                                            <li><Link to="flota">Flotă</Link></li>
                                            <li><Link to="service">Service auto</Link></li>
                                            <li><Link to="contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 order-2 order-lg-3">
                                {/* Contact Widget */}
                                <div className="widget contact-widget mb-50">
                                    <h4 className="widget-title">Contactați-ne</h4>
                                    <div className="contact-lists">
                                        <div className="contact-box">
                                            <div className="icon">
                                                <i className="flaticon-call" />
                                            </div>
                                            <div className="desc">
                                                <h6 className="title">Telefon</h6>
                                                <a href="tel:+40256201023" > +40 256 201 023</a>
                                            </div>
                                        </div>
                                        <div className="contact-box">
                                            <div className="icon">
                                                <i className="flaticon-message" />
                                            </div>
                                            <div className="desc">
                                                <h6 className="title">Adresa Email</h6>
                                                <a href="mailto:office@superimposer.ro" >office.superimposer@gmail.com</a>
                                            </div>
                                        </div>
                                        <div className="contact-box">
                                            <div className="icon">
                                                <i className="flaticon-location-pin" />
                                            </div>
                                            <div className="desc">
                                                <h6 className="title">Adresa</h6>
                                                Str. Principală Nr.832, Cod postal 307245, Liebling, Timiș
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area pt-30 pb-30">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-5 order-2 order-md-1">
                                <p className="copyright-text copyright-two">Copyright © 2022 Super Imposer</p>
                            </div>
                            <div className="col-lg-6 col-md-7 order-1 order-md-2">
                                <div className="footer-menu text-center text-md-right">
                                    <ul>
                                        <li><Link to="/termeni">Termeni și condiții</Link></li>
                                        <li><Link to="/gdpr">GDPR</Link></li>
                                        <li><Link to="/confidential">Confidențial</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;