import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Trasee = React.lazy(() => import("./components/pages/Trasee"));
const Parteneri = React.lazy(() => import("./components/pages/Parteneri"));
const Service = React.lazy(() => import("./components/pages/Service"));
const Agricultura = React.lazy(() => import("./components/pages/Agricultura"));
const Anunturi = React.lazy(() => import("./components/pages/Anunturi"));
const Flota = React.lazy(() => import("./components/pages/Flota"));
const Galerie = React.lazy(() => import("./components/pages/Galerie"));
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const GDPR = React.lazy(() => import("./components/pages/GDPR"));
const Confidential = React.lazy(() => import("./components/pages/Confidential"));
const Termeni = React.lazy(() => import("./components/pages/Termeni"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

function App() {
 
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Preloader />
          <Header headercolor=""/>
          <Route path="/" exact component={Home} />
          <Route path="/trasee" exact component={Trasee} />
          <Route path="/parteneri" exact component={Parteneri} />
          <Route path="/service" exact component={Service} />
          <Route path="/agricultura" exact component={Agricultura} />
          <Route path="/anunturi" exact component={Anunturi} />
          <Route path="/flota" exact component={Flota} />
          <Route path="/galerie" exact component={Galerie} />
          <Route path="/despre" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/gdpr" exact component={GDPR} />
          <Route path="/confidential" exact component={Confidential} />
          <Route path="/termeni" exact component={Termeni} />
          <Footer footer={{ style:"", logo:"assets/img/footer-logo.png" }}/>
          <CookieConsent
            enableDeclineButton 
            acceptOnScroll
            acceptOnScrollPercentage="25"
            declineButtonText="Nu sunt de acord"
            declineButtonStyle={{ background:"Gray", color: "White", borderRadius:"10px" }}
            location="bottom"
            buttonText="De acord"
            cookieName="consent-cookie"
            style={{ background: "black", color: "#000" }}
            buttonStyle={{ background:" #F6BE00", color: "Black", borderRadius:"10px" }}
            buttonClasses="p-2 ps-5 pe-5"
            declineButtonClasses="p-2"
            expires={150}
          >
            <div className='row'>
              <div className='col-md-12' style={{ color:"White", fontSize: 16 }}>Acest site utilizează cookies-uri</div>
              <div className='col-md-12' style={{ color:"White", fontSize: 16 }}>Utilizând scrool în conținutul site-ului vom considera că acceptați cookies-urile</div>
            </div>
          </CookieConsent>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
