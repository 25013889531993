import React from 'react'

function Flag (props){
return(
  <div className="absolute-position top-0 start-0">
    {props.lang==='ro' &&
      <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24"viewBox="0 0 3 2">
        <rect width="3" height="2" x="0" y="0" fill="#002B7F"/>
        <rect width="2" height="2" x="1" y="0" fill="#FCD116"/>
        <rect width="1" height="2" x="2" y="0" fill="#CE1126"/>
      </svg>
    }
    {props.lang=='en' &&
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="24" height="24">
        <clipPath id="s">
            <path d="M0,0 v30 h60 v-30 z"/>
        </clipPath>
        <clipPath id="t">
            <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
        </clipPath>
        <g clip-path="url(#s)">
            <path d="M0,0 v30 h60 v-30 z" fill="#012169"/>
            <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/>
            <path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/>
            <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/>
            <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" stroke-width="6"/>
        </g>
      </svg> 
    }
    {props.lang=='de' &&
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 5 3">
        <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000"/>
        <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00"/>
        <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
      </svg>
    }
    {props.lang=='fr' &&      
      <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24"viewBox="0 0 3 2">
        <rect width="3" height="2" x="0" y="0" fill="#ED2939"/>
        <rect width="2" height="2" x="1" y="0" fill="#fff"/>
        <rect width="1" height="2" x="2" y="0" fill="#002395"/>
      </svg>
    }
    {props.lang=='it' &&
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 3 2">
        <rect width="3" height="2" fill="#009246"/>
        <rect width="2" height="2" x="1" fill="#fff"/>
        <rect width="1" height="2" x="2" fill="#ce2b37"/>
      </svg>
    }
    {props.lang=='hu' &&
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 5 3">
        <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#436F4D"/>
        <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#FFF"/>
        <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#CD2A3E"/>
      </svg>
    }
  </div>
)
}

export default Flag;